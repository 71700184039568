<template>
  <div class="default-inputfield">
    <p v-if="title" :class="{ fat }">
      {{ title }} <span v-if="subtitle">{{ subtitle }}</span>
    </p>
    <input
      :class="{ center: center, invalid: invalid }"
      :type="fieldType"
      :placeholder="placeholder"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :autocomplete="autocomplete"
      name="text-input"
      @input="updateValue($event.target.value)"
      @change="updateChange($event.target.value)"
      @keypress="onlyNumber"
      @keypress.enter="
        updateChange($event.target.value);
        $event.target.blur();
      "
      :maxlength="maxlength"
      :value="value"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: "DefaultTextInput",
  props: [
    "title",
    "placeholder",
    "value",
    "maxlength",
    "disabled",
    "center",
    "invalid",
    "fat",
    "subtitle",
    "type",
    "autocomplete",
  ],
  data() {
    return {};
  },
  computed: {
    fieldType() {
      return this.type ? this.type : "text";
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode;
      if (this.type == "number") {
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 13) {
          // 46 is dot
          $event.preventDefault();
        } else if (keyCode == 13) {
          this.updateValue($event.target.value);
          $event.target.blur();
        }
      }
    },
    updateValue: function (value) {
      this.$emit("input", value);
    },
    updateChange: function (value) {
      this.$emit("change", value);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.default-inputfield {
  width: 100%;
}
p {
  font-size: 16px;
  margin-bottom: 10px;

  &.fat {
    @include Gilroy-Bold;
  }

  span {
    color: $strom;
    @include Gilroy-Regular;
  }
}
// iOS input shadow removal
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input {
  height: 48px;
  border: 1px solid $blizzard;
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  transition: all ease 0.3s;
  @include Gilroy-Bold;
  background-color: transparent;
  text-overflow: ellipsis;

  &.invalid {
    border: 1px solid $dusk;
    box-shadow: 0 0 0px 1px $dusk;

    &:hover,
    &:focus {
      border: 1px solid $dusk;
      box-shadow: 0 0 0px 1px $dusk;
    }
  }

  &:disabled {
    color: $strom;
    background-color: $mist;
    -webkit-text-fill-color: $strom;
    opacity: 1;

    &::placeholder {
      color: $blizzard;
      opacity: 1;
      transition: all ease 0.3s;
    }
  }

  &::placeholder {
    color: $blizzard;
    opacity: 1;
    transition: all ease 0.3s;
    @include Gilroy-Medium;
  }

  &:hover,
  &:focus {
    border: 1px solid $midnight;
    transition: all ease 0.3s;
  }

  &.center {
    text-align: center;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .default-inputfield {
    width: calc(50% - 20px);
  }
}
</style>
